export const environment = {
  production: false,
  apiUrl: 'https://api.plataformax8.com.br/api/v1/',
  mercadoLivreAppId: '5683408383520710',
  shopeePartnerId: "2008103",
  sheinPartnerId: "10ED952E7000185DF14B563272F5E",
  shopeeRedirectUrl: "https://app.plataformax8.com.br",
  sheinRedirectUrl: "aHR0cHM6Ly9hcHAucGxhdGFmb3JtYXg4LmNvbS5ici9zYWxlc2NoYW5uZWxzL1NIRUlO",
};

